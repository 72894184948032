import './navbar.css';
import { Link } from 'react-router-dom';
import Img1 from './Logo.png';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useState } from 'react';
import { VscThreeBars } from 'react-icons/vsc'

const Navbar = () => {
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const handleMenuItemClick = () => {
        setState(false);
    };

    return (
        <>
            <div className='navbar'>
                <div className='navbar2'>
                    <div className='navbar_logo'><img src={Img1} alt="" width='100%' /></div>
                    <div className='navbar_item'>
                        <ul className='navbar_ul hover_line'>
                            <Link to='/'><li>Home</li></Link>
                            <hr className='hr_line' />
                            <li className="nav_list nav_link work_downdrop active-nav">
                                About Us
                                <div className='work_hide work_drop'>
                                    <ul>
                                        <Link to='/Mission' ><li className="nav_list">Mission</li></Link>
                                        <Link to='/Vision' ><li className="nav_list">Vision</li></Link>
                                        <Link to='/management' ><li className="nav_list">Management</li></Link>
                                        <Link to='/csr' ><li className="nav_list">CSR</li></Link>
                                    </ul>
                                </div>
                            </li>
                            <hr className='hr_line' />
                            <Link to='/project'><li>Projects</li></Link>
                            <hr className='hr_line' />
                            <Link to='/gallery'><li>Gallery</li></Link>
                            <hr className='hr_line' />
                            <Link to='/contact'><li>Contact</li></Link>
                        </ul>
                    </div>
                    <div className='Search_icon'>
                    </div>
                    <div>
                        <div className='handburger'>
                            <div onClick={() => setState({ isPaneOpen: true })} className='handbuger_icons'>
                                <VscThreeBars size='25px' />
                            </div>
                            <SlidingPane
                                className="some-custom-class hello"
                                overlayClassName="some-custom-overlay-class"
                                isOpen={state.isPaneOpen}
                                onRequestClose={() => {
                                    setState({ isPaneOpen: false });
                                }}
                            >
                                <ul className='items handburger_item'>
                                    <Sidebar className='navbar_handburger' width='250px'>
                                        <Menu className='navbar_menu'>
                                            <MenuItem component={<Link to="/" />} onClick={handleMenuItemClick} className='navbar_menuiten'> Home</MenuItem>
                                            <SubMenu label="about">
                                                <MenuItem component={<Link to="/vision" />} onClick={handleMenuItemClick}> Vision </MenuItem>
                                                <MenuItem component={<Link to="/mission" />} onClick={handleMenuItemClick}> Mission </MenuItem>
                                                <MenuItem component={<Link to="/management" />} onClick={handleMenuItemClick}> Management </MenuItem>
                                                <MenuItem component={<Link to="/csr" />} onClick={handleMenuItemClick}> CSR </MenuItem>
                                            </SubMenu>
                                            <MenuItem component={<Link to="/project" />} onClick={handleMenuItemClick} >Project</MenuItem>
                                            <MenuItem component={<Link to="/gallery" />} onClick={handleMenuItemClick} >Gallery</MenuItem>
                                            <MenuItem component={<Link to="/contact" />} onClick={handleMenuItemClick} >Contact</MenuItem>
                                        </Menu>
                                    </Sidebar>
                                </ul>
                                <br />
                            </SlidingPane>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Navbar