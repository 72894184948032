import './contacthome.css';

const Contacthome = () => {
  return (
    <>
    <div className="Contact_container">
        <div className="head_contact"><p> Get In <span className="touch">Touch</span></p></div>
        <div className="para_contact"><p></p></div>
        <div className="input_email"><input type="text" placeholder=" You Email " /></div>
        <div className="button_contact">
            <div><p>Contact Us</p></div></div>
    </div>
    </>
  )
}

export default Contacthome