import Img1 from './Logo.png';
import './footer.css';
const Footer = () => {
  return (
    <>
      <div className='footer'>
        <div className='footer1'>
          <div className='foot_img'>
            <img src={Img1} alt="" width='100%'/>
          </div>
          <div className='input'>
            <input type="text" placeholder='E-mail' />
          </div>
          <div className='para_foot'>
            <p>© 2023 Silicon Homes. All Rights Reserved.</p>
          </div>
          <div className=''>
            social media
          </div>
        </div>
        <div className='footer_details'>
          <p className='head_foot'>Lucknow</p>
          <ul className='footer_ul'>
            <li>+91 9919006600</li>
            <li>Gomti Nagar Ext. Road Lucknow</li>
            <li>226002, India</li>
            <li>info@siliconhome.in</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Footer