import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contacthome from './components/Contacthome';
import Loading from './components/loading/Loading';

const Home = lazy(() => import('./components/page/Home'));
const Project = lazy(() => import('./pages/Project'));
const Contact = lazy(() => import('./pages/Contact'));
const Vision = lazy(() => import('./pages/about/Vision'));
const Mision = lazy(() => import('./pages/about/Mission'));
const Gallery = lazy(() => import('./pages/Gallery'));
const Csr = lazy(() => import('./pages/about/Csr'));
const Management = lazy(() => import('./pages/management/Management'));
function App() {
  return (
    <>
    <Suspense fallback={<Loading/>}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/project" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mission" element={<Mision />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/Csr" element={<Csr />} />
          <Route path="/management" element={<Management />} />
        </Routes>
        <Contacthome />
        <Footer />
      </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
